import React, { useState, useEffect, useRef } from 'react';
import { getAuth, sendPasswordResetEmail, fetchSignInMethodsForEmail } from 'firebase/auth';
import { IdentificationBadge, PaperPlaneTilt, XCircle } from '@phosphor-icons/react';

const errorMessages = {
  "auth/invalid-email": "Please enter a valid username.",
  "auth/user-not-found": "This user does not exist.",
  "auth/wrong-password": "Incorrect password. Please try again.",
  "auth/too-many-requests": "Too many unsuccessful attempts. Please try again later.",
  "auth/network-request-failed": "Network error. Please check your internet connection.",
  "default": "An error occurred. Please try again later."
};

const ForgotPassword = ({ onClose }) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [emailError, setEmailError] = useState('');

    const emailInputRef = useRef(null);
    const domainSpanRef = useRef(null);
    const emailContainerRef = useRef(null);

    useEffect(() => {
        let timer;
        if (isSubmitted) {
            timer = setTimeout(() => {
                onClose();
            }, 5000);
        }
        return () => clearTimeout(timer);
    }, [isSubmitted, onClose]);

    useEffect(() => {
        updateDomainPosition();
        window.addEventListener('resize', updateDomainPosition);
        return () => window.removeEventListener('resize', updateDomainPosition);
    }, [email]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const auth = getAuth();
        const fullEmail = email + '@apersu.co.uk';
        
        try {
            // Check if the user exists
            const signInMethods = await fetchSignInMethodsForEmail(auth, fullEmail);
            
            if (signInMethods.length === 0) {
                // User doesn't exist
                setMessage(errorMessages["auth/user-not-found"]);
            } else {
                // User exists, send password reset email
                await sendPasswordResetEmail(auth, fullEmail);
                setMessage("We've sent you an email with instructions on how to reset your password. Please check your inbox.");
                setIsSubmitted(true);
            }
        } catch (error) {
            const errorCode = error.code;
            setMessage(errorMessages[errorCode] || errorMessages.default);
        }
    };

    const handleEmailChange = (e) => {
        const inputValue = e.target.value;
        setEmail(inputValue);

        // Email validation
        const emailRegex = /^[a-zA-Z0-9._-]+$/;
        if (!emailRegex.test(inputValue)) {
            setEmailError("Invalid email format");
        } else {
            setEmailError("");
        }

        updateDomainPosition();
    };

    const updateDomainPosition = () => {
        if (domainSpanRef.current && emailInputRef.current && emailContainerRef.current) {
            const containerRect = emailContainerRef.current.getBoundingClientRect();
            const inputRect = emailInputRef.current.getBoundingClientRect();
            const domainRect = domainSpanRef.current.getBoundingClientRect();
            const inputStyles = window.getComputedStyle(emailInputRef.current);

            const inputPaddingLeft = parseFloat(inputStyles.paddingLeft);
            const inputPaddingRight = parseFloat(inputStyles.paddingRight);

            // Create a hidden span to measure text width
            const measurer = document.createElement('span');
            measurer.style.visibility = 'hidden';
            measurer.style.position = 'absolute';
            measurer.style.whiteSpace = 'pre';
            measurer.style.font = inputStyles.font;
            measurer.textContent = email;
            document.body.appendChild(measurer);

            const textWidth = measurer.offsetWidth;
            document.body.removeChild(measurer);

            const buffer = 49; // Small buffer for visual comfort

            let newLeft;
            if (email.length === 0) {
                // Position after the placeholder
                newLeft = inputPaddingLeft + 114; // Adjust based on your placeholder width
            } else {
                newLeft = Math.min(
                    inputPaddingLeft + textWidth + buffer,
                    containerRect.width - inputPaddingRight - domainRect.width
                );
            }

            domainSpanRef.current.style.left = `${newLeft}px`;
        }
    };

    return (
        <div className="modal modal-open">
            <div className="modal-box">
                <h3 className="font-bold text-lg text-left">If you've forgotten your password, enter your staff username here and we'll send you a password reset link.</h3>
                <form onSubmit={handleSubmit}>
                    <div ref={emailContainerRef} className="input input-bordered flex items-center gap-2 mt-4 relative">
                        <IdentificationBadge size={25}/>
                        <input
                            ref={emailInputRef}
                            type="text"
                            placeholder="username"
                            value={email}
                            onChange={handleEmailChange}
                            required
                            className="w-full bg-transparent outline-none"
                        />
                        <span 
                            ref={domainSpanRef}
                            className="absolute text-base-content opacity-70 transition-all duration-600 ease-in-out whitespace-nowrap"
                            style={{ pointerEvents: 'none' }}
                        >
                            @apersu.co.uk
                        </span>
                    </div>

                    {message && <p className="mt-4 text-info">{message}</p>}
                    {!message && <p className="mt-4 text-info"><br/></p>}
                    {/* {emailError && <p className="text-error mt-2">{emailError}</p>} */}
                    <div className="modal-action">
                        <button type="submit" className="btn btn-secondary"><PaperPlaneTilt size={25}/>Send Reset Email</button>
                        <button type="button" onClick={onClose} className="btn btn-primary"><XCircle size={30}/>Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ForgotPassword;