import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLogin } from "../../hooks/useLogin";
import ForgotPassword from "./ForgotPassword";
import LoadingButton from "../../components/Buttons/LoadingButton";
import LoginButton from "../../components/Buttons/LoginButton";
import {
  Eye,
  EyeSlash,
  IdentificationBadge,
  Password,
} from "@phosphor-icons/react";

const errorMessages = {
  EMPTY_FIELDS: "Please fill in both username and password.",
  INVALID_CREDENTIALS: "Invalid username or password. Please check your credentials and try again.",
  WRONG_PASSWORD: "Incorrect password. Please try again.",
  INVALID_EMAIL: "Invalid email format. Please check your username.",
  USER_DISABLED: "This account has been disabled. Please contact support.",
  TOO_MANY_REQUESTS: "Too many failed login attempts. Please try again later.",
  NETWORK_ERROR: "Network error. Please check your internet connection and try again.",
  DEFAULT: "An error occurred. Please try again.",
};

export default function LoginForm() {
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [formError, setFormError] = useState("");
  const { login, error: loginError, isPending, clearError } = useLogin();

  const usernameInputRef = useRef(null);
  const domainSpanRef = useRef(null);
  const usernameContainerRef = useRef(null);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setFormError("");
      if (!username || !password) {
        setFormError(errorMessages["EMPTY_FIELDS"]);
        return;
      }
      if (!usernameError) {
        await login(username, password);
      }
    },
    [username, password, usernameError, login]
  );

  const handleUsernameChange = useCallback(
    (e) => {
      const inputValue = e.target.value;
      setUsername(inputValue);
      setFormError("");

      // Username validation
      const usernameRegex = /^[a-zA-Z0-9._-]+$/;
      if (!usernameRegex.test(inputValue)) {
        setUsernameError("Invalid username format");
      } else {
        setUsernameError("");
      }

      // Clear any existing errors when the user starts typing
      clearError();
      updateDomainPosition();
    },
    [clearError]
  );

  const handlePasswordChange = useCallback(
    (e) => {
      setPassword(e.target.value);
      setFormError("");
      clearError();
    },
    [clearError]
  );

  // Update the position of "@apersu.co.uk" as a user types
  const updateDomainPosition = useCallback(() => {
    if (
      domainSpanRef.current &&
      usernameInputRef.current &&
      usernameContainerRef.current
    ) {
      const containerRect =
        usernameContainerRef.current.getBoundingClientRect();
      const inputRect = usernameInputRef.current.getBoundingClientRect();
      const domainRect = domainSpanRef.current.getBoundingClientRect();
      const inputStyles = window.getComputedStyle(usernameInputRef.current);

      const inputPaddingLeft = parseFloat(inputStyles.paddingLeft);
      const inputPaddingRight = parseFloat(inputStyles.paddingRight);

      // Create a hidden span to measure text width
      const measurer = document.createElement("span");
      measurer.style.visibility = "hidden";
      measurer.style.position = "absolute";
      measurer.style.whiteSpace = "pre";
      measurer.style.font = inputStyles.font;
      measurer.textContent = username;
      document.body.appendChild(measurer);

      const textWidth = measurer.offsetWidth;
      document.body.removeChild(measurer);

      const buffer = 49; // Small buffer for visual comfort

      let newLeft;
      if (username.length === 0) {
        // Position after the placeholder
        newLeft = inputPaddingLeft + 116; // Adjust based on your placeholder width
      } else {
        newLeft = Math.min(
          inputPaddingLeft + textWidth + buffer,
          containerRect.width - inputPaddingRight - domainRect.width
        );
      }

      domainSpanRef.current.style.left = `${newLeft}px`;
    }
  }, [username]);

  // Eye icon to show/hide the password
  const togglePasswordVisibility = useCallback(() => {
    setShowPassword(!showPassword);
  }, []);

  useEffect(() => {
    updateDomainPosition();
    window.addEventListener("resize", updateDomainPosition);
    return () => window.removeEventListener("resize", updateDomainPosition);
  }, [username, updateDomainPosition]);

  useEffect(() => {
    if (loginError) {
      setFormError(errorMessages[loginError] || errorMessages.DEFAULT);
    }
  }, [loginError]);

  return (
    <div className="text-center mx-auto bg-base-100">
      <div className="flex-col">
        <div className="mx-auto sm:w-1/3">
          <div className="divider mx-auto divider-primary pageTitleSm">
            Staff Login
          </div>
        </div>
        <br />
        <div className="card mx-auto w-full max-w-xl shadow-2xl bg-base-200">
          <form className="card-body" onSubmit={handleSubmit}>
            <div className="form-control pb-3">
              <div
                ref={usernameContainerRef}
                className="input input-bordered flex items-center gap-2 relative">
                <IdentificationBadge size={25} />
                <input
                  ref={usernameInputRef}
                  type="text"
                  className="grow bg-transparent outline-none"
                  placeholder="username"
                  id="1username"
                  onChange={handleUsernameChange}
                  value={username}
                />
                <span
                  ref={domainSpanRef}
                  className="absolute text-base-content opacity-65 transition-all duration-600 ease-in-out whitespace-nowrap"
                  style={{ pointerEvents: "none" }}>
                  @apersu.co.uk
                </span>
              </div>
              {usernameError && (
                <p className="text-error text-md mt-1">{usernameError}</p>
              )}
            </div>
            <div className="form-control">
              <label className="input input-bordered flex items-center gap-2 pe-0">
                <Password size={25} />
                <input
                  type={showPassword ? "text" : "password"}
                  className="grow"
                  placeholder="Password"
                  id="1password"
                  onChange={handlePasswordChange}
                  value={password}
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="btn btn-link text-base-content">
                  {showPassword ? <EyeSlash size={25} /> : <Eye size={25} />}
                </button>
              </label>
              <button
                type="button"
                className="btn btn-secondary btn-sm w-auto mt-2"
                onClick={() => setShowForgotPassword(true)}>
                Forgot Password?
              </button>
            </div>
            <div className="form-control mt-14 ">
              {!isPending ? (
                <LoginButton
                  type="submit"
                  disabled={!username || !password || !!usernameError}
                />
              ) : (
                <LoadingButton />
              )}
              {formError && (
                <div className="alert alert-error mt-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current shrink-0 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span>{formError}</span>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      {showForgotPassword && (
        <ForgotPassword onClose={() => setShowForgotPassword(false)} />
      )}
    </div>
  );
}
