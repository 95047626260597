import { useState, useCallback } from "react";
import { apersuAuthentication } from "../firebase/config";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useAuthContext } from "./useAuthContext";

export const useLogin = () => {
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const { dispatch } = useAuthContext();

  const login = useCallback(async (username, password) => {
    setError(null);
    setIsPending(true);

    try {
      if (!username || !password) {
        throw new Error("EMPTY_FIELDS");
      }

      // Append the domain to the username
      const email = `${username}@apersu.co.uk`;

      // Attempt to sign in
      const res = await signInWithEmailAndPassword(apersuAuthentication, email, password);
      dispatch({ type: "LOGIN", payload: res.user });
      setIsPending(false);
    } catch (err) {
      console.error("Login error:", err.code, err.message);
      
      // Handle specific Firebase auth errors
      switch(err.code) {
        case "auth/user-not-found":
        case "auth/invalid-credential":
          setError("INVALID_CREDENTIALS");
          break;
        case "auth/wrong-password":
          setError("WRONG_PASSWORD");
          break;
        case "auth/invalid-email":
          setError("INVALID_EMAIL");
          break;
        case "auth/user-disabled":
          setError("USER_DISABLED");
          break;
        case "auth/too-many-requests":
          setError("TOO_MANY_REQUESTS");
          break;
        case "auth/network-request-failed":
          setError("NETWORK_ERROR");
          break;
        default:
          setError("DEFAULT");
          console.error("Unexpected error during login:", err);
      }

      setIsPending(false);
    }
  }, [dispatch]);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  return { login, error, isPending, clearError };
};