import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { UserCircle } from '@phosphor-icons/react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { doc, setDoc } from 'firebase/firestore';
import { apersuDatabase } from '../../firebase/config';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAvatarContext } from '../../context/avatar-context';

export default function StaffProfilePicCard() {
  const { user } = useAuthContext();
  const { avatarUrl, setAvatarUrl } = useAvatarContext();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const fetchAvatar = async () => {
      if (!user?.uid) return;

      const storage = getStorage();
      const avatarRef = ref(storage, `staffAvatars/${user.uid}/Avatar`);

      try {
        const url = await getDownloadURL(avatarRef);
        setAvatarUrl(url);
      } catch (error) {
        console.error("Error fetching avatar:", error);
        try {
          const defaultUrl = await getDownloadURL(ref(storage, 'staffAvatars/default/defaultAvatar.png'));
          setAvatarUrl(defaultUrl);
        } catch (defaultError) {
          console.error("Error fetching default avatar:", defaultError);
        }
      }
    };

    fetchAvatar();
  }, [user, setAvatarUrl]);

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (!file.type.startsWith('image/')) {
      toast.error('Please select a valid image file.');
      return;
    }

    if (!user?.uid) {
      toast.error('You must be logged in to upload an avatar.');
      return;
    }

    setIsUploading(true);
    setUploadProgress(0);

    const storage = getStorage();
    const avatarRef = ref(storage, `staffAvatars/${user.uid}/Avatar`);
    const uploadTask = uploadBytesResumable(avatarRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        console.error("Upload error:", error);
        toast.error(`Upload failed: ${error.message}`);
        setIsUploading(false);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setAvatarUrl(downloadURL);

          const userDocRef = doc(apersuDatabase, `/staff/${user.uid}/info/profile`);
          await setDoc(userDocRef, { avatarUrl: downloadURL }, { merge: true });

          toast.success("Profile updated successfully!", {
            autoClose: 2000,
            containerId: "staffAvatar"
          });
        } catch (error) {
          console.error("Error updating user document:", error);
          toast.error(`Failed to update profile: ${error.message}`);
        } finally {
          setIsUploading(false);
        }
      }
    );
  };

  return (
    <div className='text-center'>
      <ToastContainer 
    limit={1} 
    autoClose={2000} 
    enableMultiContainer 
    containerId="staffAvatar"
  />
      <div className="card card-compact md:card-side bg-base-200 xl:max-w-screen-2xl md:max-w-7xl md:min-w-xl mx-auto shadow-xl mb-20">
        <div className="card-body">
          <h2 className="card-title text-left">Change your Avatar</h2>
          <div className="max-sm:text-sm label text-left">
            Upload an image here to use it as your staff avatar.<br/>This image is never shared outside of the company.
          </div>
          <div className="card-actions md:justify-start justify-center">
            <div className='text-left'>
                <label className="form-control w-full justify-items-start max-w-xl">
                  <input 
                    className="file-input file-input-bordered file-input-secondary w-full"
                    type="file"
                    accept="image/*"
                    onChange={handleFileSelect}
                    disabled={isUploading}
                  />
                  <div className="label">
                    {isUploading && (
                      <progress className="progress progress-secondary w-full" value={uploadProgress} max="100"></progress>
                    )}
                  </div>
                  {isUploading ? (
                    <button 
                      className="btn max-md:btn-sm btn-secondary"
                      disabled
                    >
                      <UserCircle size={25}/> Uploading...
                    </button>
                  ) : (
                    <button 
                      className="btn max-md:btn-sm btn-secondary"
                      onClick={() => document.querySelector('input[type="file"]').click()}
                    >
                      <UserCircle size={25}/> Update Your Avatar
                    </button>
                  )}
                </label>
            </div>
          </div>
        </div>
        <figure className='md:w-2/5 p-2'>
          <img
            className='cardImage companyLogoImage'
            src={avatarUrl}
            alt={user ? `${user.displayName} Avatar` : 'Staff Avatar'}
          />
        </figure>
      </div>
    </div>
  );
}