import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useAuthContext } from './hooks/useAuthContext';
import { AvatarProvider } from './context/avatar-context';
//styles
import './App.css';
import './output.css';
//pages and components
import Navbar from './components/NavBar/Navbar';
import Login from './pages/Login';
import Dashboard from './pages/User/Dashboard';
import Footer from './components/Footer/Footer';
import CustomerInformation from './pages/User/CustomerInformation';
import EstimateInformation from './pages/User/EstimateInformation';
import AllocatedEstimates from './pages/User/AllocatedEstimates';
import DefaultCosts from './pages/User/DefaultCosts';
import AllocateWork from './pages/User/AllocateWork';
import CreateStaffUser from './pages/User/CreateStaffUser'
import StaffProfileSettings from './pages/User/StaffProfileSettings'


function App() {
  const { authIsReady, user } = useAuthContext()

    
  useEffect(() => {
    console.log('App rendered. authIsReady:', authIsReady, 'user:', user);
  }, [authIsReady, user]);

  return (
    <AvatarProvider>
    <div className="App">
      {authIsReady && (
        <BrowserRouter>
          <Navbar />
          <Routes>
          <Route path="/" element={user ? <Dashboard user={user} uid={user.uid} /> : <Navigate to="/Login" />} />
          <Route path="/CustomerInformation" element={user ? <CustomerInformation user={user} uid={user.uid} /> : <Navigate to="/Login" />} />
          <Route path="/EstimateInformation" element={user ? <EstimateInformation user={user} uid={user.uid} /> : <Navigate to="/Login" />} />
          <Route path="/AllocatedEstimates" element={user ? <AllocatedEstimates user={user} uid={user.uid} /> : <Navigate to="/Login" />} />
          <Route path="/DefaultCosts" element={user ? <DefaultCosts user={user} uid={user.uid} /> : <Navigate to="/Login" />} />
          <Route path="/AllocateWork" element={user ? <AllocateWork user={user} uid={user.uid} /> : <Navigate to="/Login" />} />
          <Route path="/CreateStaffUser" element={user ? <CreateStaffUser user={user} uid={user.uid} /> : <Navigate to="/Login" />} />
          <Route path="/StaffProfileSettings" element={user ? <StaffProfileSettings user={user} uid={user.uid} /> : <Navigate to="/Login" />} />
            
            
            <Route path="/Login" element={!user ? <Login /> : <Navigate to="/" />} />


          </Routes>
          <Footer />
        </BrowserRouter>
      )}
    </div>
    </AvatarProvider>
  );
}
export default App;