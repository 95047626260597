import React from 'react';
import ReactDOM from 'react-dom/client';
//styles
import './index.css';
import './output.css';
//pages and components
import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <React.StrictMode>
    <AuthContextProvider>
      <App /> 
    </AuthContextProvider>
  </React.StrictMode>
);