import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useLogout } from "../../hooks/useLogout";
import { useAuthContext } from "../../hooks/useAuthContext";
import { IdentificationCard, SignOut, Warning, XCircle } from "@phosphor-icons/react";
import DefaultAvatar from '../../assets/images/defaultAvatar.png'
import UserName from "./UserName";
import { useAvatarContext } from '../../context/avatar-context';

export default function Navbar() {
  const { logout } = useLogout();
  const { user, authIsReady } = useAuthContext();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { avatarUrl } = useAvatarContext();

  const handleLogout = () => {
    logout();
    document.getElementById("signOutModal").close();
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const handleNavigation = (path) => {
    closeDropdown();
    navigate(path);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if (!authIsReady) return null;

  return (
    <>
      {!user && (
        <div className="sticky top-0 z-50 bg-base-100 px-auto pt-0 h-32 shadow-xl">
          <span className="ae-logo">æ</span>
        </div>
      )}
      {user && (
        <div className="sticky top-0 z-40 bg-base-100 px-16 flex flex-col h-24 pt-2 pb-20 mb-10 shadow-xl">
          <div className="navbar w-full px-16 flex justify-between items-center">
            <div className="navbar-start mt-7">
              <Link to="/" onClick={closeDropdown}>
                <span className="ae-logo">æ</span>
              </Link>
            </div>
            <div className="relative flex flex-row text-right mt-3" ref={dropdownRef}>
              <div>
                Logged in as <br/>
                <UserName />
              </div>
              <button 
                aria-haspopup="true"
                aria-expanded={isDropdownOpen}
                className="btn btn-ghost btn-circle avatar userAvatar ms-5"
                onClick={toggleDropdown}
              >
                <div className="h-15 rounded-full">
                  <img
                    alt="User Avatar"
                    src={avatarUrl || DefaultAvatar}
                  />
                </div>
              </button>
              {isDropdownOpen && (
                <ul
                  className="absolute right-0 mt-12 w-72 bg-base-100 rounded-box shadow-xl p-2 border-primary bordered border-2"
                  style={{ top: '100%' }}
                >
                  <li>
                    <button 
                      className="btn btn-md btn-secondary w-full text-left"
                      onClick={() => handleNavigation('/StaffProfileSettings')}
                    >
                      <IdentificationCard size={25}/>
                      Profile Settings
                    </button>
                  </li>
                  <li>
                    <button
                      className="btn btn-sm btn-primary w-full text-left mt-2"
                      onClick={() => {
                        closeDropdown();
                        document.getElementById("signOutModal").showModal();
                      }}
                    >
                      <SignOut size={25} />
                      Sign Out
                    </button>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Logout Modal */}
      <dialog id="signOutModal" className="modal">
        <div className="modal-box border-primary bordered border-4">
          <form method="dialog">
            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
              <XCircle size={30} />
            </button>
          </form>
          <h3 className="font-bold pageSubtitle text-lg">Confirm Sign Out</h3>
          <p className="py-4">
            Are you sure you want to sign out?
            <br />
            You will be returned to the sign in screen.
          </p>
          <button className="btn btn-wide btn-primary" onClick={handleLogout}>
            <Warning size={35} />
            &nbsp;Sign Out
          </button>
          <br />
          <br />
          Something not right?{" "}
          <a
            className="blueLink"
            target="_blank"
            rel="noreferrer"
            href="https://docs.google.com/document/d/1W7NFA0rZH_uCiZxQYNOReiK3W4z9i4Kbn9zMXj7NHFE/edit">
            Report it here.
          </a>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </>
  );
}