//firebase components
      import { initializeApp } from "firebase/app";
      import { getFirestore, Timestamp } from "firebase/firestore";
      import { getAuth, onAuthStateChanged } from "firebase/auth";
      import { getStorage } from "firebase/storage";
      import { getFunctions } from 'firebase/functions';

// firebase config const firebaseConfig = {

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
      apiKey: "AIzaSyCJP85MehnzVfhKpum3wLYnFHZkVNVRtPA",
      authDomain: "apersutechnology.firebaseapp.com",
      databaseURL: "https://apersutechnology-default-rtdb.europe-west1.firebasedatabase.app",
      projectId: "apersutechnology",
      storageBucket: "apersutechnology.appspot.com",
      messagingSenderId: "611050399801",
      appId: "1:611050399801:web:6504ecbf5885672a4bc5e8",
      measurementId: "G-0139T43CTV"
    };

// init firebase
      
      const app = initializeApp(firebaseConfig);
// init services
      const apersuDatabase       = getFirestore(app);
      const apersuAuthentication = getAuth(app);
      const apersuStorage        = getStorage(app);
      const timestamp            = new Timestamp(app);
      const functions            = getFunctions(app);

// exports
      export { apersuDatabase, apersuAuthentication, apersuStorage, timestamp, functions }