import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import {
  doc,
  setDoc,
  updateDoc,
  serverTimestamp,
  onSnapshot,
} from "firebase/firestore";
import { updateProfile, getIdToken } from "firebase/auth";
import { apersuDatabase, apersuAuthentication } from "../../firebase/config";
import debounce from "lodash/debounce";
import { useAuthContext } from "../../hooks/useAuthContext";
import {
  At,
  Phone,
  ShieldWarning,
  CheckCircle,
  Ambulance,
  UserCircle,
  Password,
} from "@phosphor-icons/react";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import StaffProfilePicCard from "./StaffProfilePicCard";

export default function StaffProfileInfo() {
  console.log("Rendering StaffProfileInfo component");

  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState(null);
  const [filledFields, setFilledFields] = useState({});

  // Using react-hook-form
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const isLoadingRef = useRef(false);

  console.log("Current user:", user);

  // Function to capitalize words in input fields
  const capitalizeWords = (value) =>
    value.replace(/\b\w/g, (char) => char.toUpperCase());

  // Function to update form values based on fetched profile data
  const updateFormValues = useCallback(
    (profileData) => {
      console.log("Updating form values with:", profileData);
      Object.entries(profileData).forEach(([key, value]) => {
        console.log(`Setting ${key} to ${value}`);
        setValue(key, value);
      });
    },
    [setValue]
  );

  // Debounce function for updating the database
  const debouncedUpdate = useCallback(
    debounce(async (data) => {
      if (!user?.uid) {
        console.warn("No user UID available for update");
        return;
      }
      try {
        console.log("Updating profile with data:", data);
        const updatedRef = doc(
          apersuDatabase,
          `staff/${user.uid}/info/profile`
        );
        await updateDoc(updatedRef, {
          ...data,
          _updatedAt: serverTimestamp(),
        });
        console.log("Profile updated successfully");
      } catch (error) {
        console.error("Error updating profile:", error);
        throw error;
      }
    }, 500),
    [user?.uid]
  );

  // Set up listener to fetch and update profile data
  useEffect(() => {
    console.log("Setting up profile listener");
    if (!user) {
      console.warn("No user available for fetching profile");
      return;
    }

    const docRef = doc(apersuDatabase, `staff/${user.uid}/info/profile`);

    const unsubscribe = onSnapshot(
      docRef,
      (docSnap) => {
        if (docSnap.exists()) {
          console.log("Profile data fetched:", docSnap.data());
          const profileData = { ...docSnap.data(), email: user.email };
          updateFormValues(profileData);
          setProfile(profileData);
          localStorage.setItem(
            `profile_${user.uid}`,
            JSON.stringify(profileData)
          );
        } else {
          console.log("No profile found, creating initial profile");
          const initialProfile = {
            uid: user.uid,
            email: user.email,
            staffUserName: user.displayName || "",
          };
          setDoc(docRef, initialProfile);
          updateFormValues(initialProfile);
          setProfile(initialProfile);
        }
        isLoadingRef.current = false;
      },
      (error) => {
        console.error("Error fetching profile:", error);
        toast.error("Error fetching profile: " + error.message);
        isLoadingRef.current = false;
      }
    );

    return () => {
      console.log("Unsubscribing from profile listener");
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [user, updateFormValues]);

  // Form submission handler
  const onSubmit = async (data) => {
    console.log("Form submitted with data:", data);
    if (isLoadingRef.current) {
      console.log("Submission already in progress, aborting");
      return;
    }
    isLoadingRef.current = true;
    setIsLoading(true);

    try {
      const updatedData = { ...data, email: user.email };
      await debouncedUpdate(updatedData);

      if (
        user &&
        data.staffUserName &&
        data.staffUserName !== user.displayName
      ) {
        console.log("Updating user display name");
        try {
          // Use the imported auth object instead of the user object
          await updateProfile(apersuAuthentication.currentUser, { displayName: data.staffUserName });
          console.log("User profile updated successfully");
          
          // Manually update the local user object
          user.displayName = data.staffUserName;
          
          // Optionally, you can force a re-render of components that depend on user data
          // by updating the user in your auth context
          // updateUser(auth.currentUser);
        } catch (updateError) {
          console.error("Error updating profile:", updateError);
          throw updateError;
        }
      }

      toast.success("Profile updated successfully!", {
        autoClose: 2000,
        containerId: "profileInfo"
      });
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Error updating profile: " + error.message, {
        autoClose: 2000,
      });
    } finally {
      isLoadingRef.current = false;
      setIsLoading(false);
    }
  };

  // Track filled fields using watch
  const watchAllFields = watch();
  const requiredFields = useMemo(
    () => [
      "staffUserName",
      "emergencyContactName",
      "emergencyContactPhone",
      "addressLine1",
      "postCode",
    ],
    []
  );

  useEffect(() => {
    console.log("Updating filled fields");
    const newFilledFields = requiredFields.reduce((acc, field) => {
      acc[field] = !!watchAllFields[field];
      return acc;
    }, {});

    setFilledFields((prevFilledFields) => {
      if (
        JSON.stringify(prevFilledFields) !== JSON.stringify(newFilledFields)
      ) {
        console.log("Filled fields updated:", newFilledFields);
        return newFilledFields;
      }
      return prevFilledFields;
    });
  }, [watchAllFields, requiredFields]);

  console.log("Rendering StaffProfileInfo JSX");

  return (
    <div className="md:px-10">
    <ToastContainer 
      limit={1} 
      autoClose={2000} 
      enableMultiContainer 
      containerId="profileInfo"
    />
      <div className="pageSubtitle text-left max-sm:ps-6 ps-10">
        Use this page to update your profile information.
      </div>
      <div className="flex flex-col sm:flex-row gap-2 sm:gap-4 my-4 md:ps-10">
        <div
          className="tooltip tooltip-bottom sm:tooltip-right"
          data-tip="Secure your account with multi-factor authentication, requiring additional information when you log in. Currently switched OFF.">
          <button className="btn btn-primary btn-sm md:w-80 max-md:w-72">
            <ShieldWarning size={25} className="md:mr-2" />
            Multi-Factor Authentication: Off
          </button>
        </div>
      </div>

      <div className="text-center card bg-base-100 xl:max-w-screen-2xl md:max-w-7xl md:min-w-xl mx-auto md:p-5 p-2 shadow-xl ">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-2 md:grid-cols-4 md:grid-rows-20 md:gap-5 max-md:gap-1">
            {/* Staff User Name */}
            <div className="row-start-1 md:col-span-2 max-md:col-span-2 w-full">
              <div className="label">
                {errors.staffUserName && (
                  <span className="label-text text-error">
                    {errors.staffUserName.message}
                  </span>
                )}
                {!errors.staffUserName && (
                  <span className="label-text">Full Name</span>
                )}
              </div>
              <label className="input input-bordered flex items-center gap-2">
                <UserCircle size={20} />
                <input
                  {...register("staffUserName", { required: "Full Name is required" })}
                  type="text"
                  className="grow"
                  placeholder="Full Name"
                  onChange={(e) => {
                    const capitalized = capitalizeWords(e.target.value);
                    e.target.value = capitalized;
                    setValue("staffUserName", capitalized);
                  }}
                />
                {!filledFields.staffUserName && (
                  <span className="badge badge-accent badge-xs"></span>
                )}
              </label>
            </div>

            {/* Staff Email Address */}
            <div className="md:col-span-2 max-md:col-span-2 md:col-start-3 sm:row-start-2 md:row-start-1 w-full">
              <div className="label">
                <span className="label-text">Company Email</span>
              </div>
              <div
                className="tooltip tooltip-warning w-full"
                data-tip="The Company Email Address cannot be changed.">
                <label className="input input-bordered flex items-center gap-2">
                  <At size={20} />
                  <input
                    {...register("email")}
                    type="email"
                    className="grow"
                    defaultValue={user?.email}
                    readOnly
                  />
                </label>
              </div>
            </div>

            {/* Emergency Contact Name */}
            <div className="md:row-start-2 md:col-start-1 max-md:col-span-2 sm:row-start-3 w-full">
              <div className="label">
                {errors.emergencyContactName && (
                  <span className="label-text text-error">
                    {errors.emergencyContactName.message}
                  </span>
                )}
                {!errors.emergencyContactName && (
                  <span className="label-text">Emergency Contact Name</span>
                )}
              </div>
              <label className="input input-bordered flex items-center gap-2 w-full">
                <Ambulance size={20} />
                <input
                  {...register("emergencyContactName", {
                    required: "Emergency Contact Name is required",
                  })}
                  type="text"
                  className="grow"
                  placeholder="Emergency Contact Name"
                  onChange={(e) => {
                    const capitalized = capitalizeWords(e.target.value);
                    e.target.value = capitalized;
                    setValue("emergencyContactName", capitalized);
                  }}
                />
                {!filledFields.emergencyContactName && (
                  <span className="badge badge-accent badge-xs"></span>
                )}
              </label>
            </div>

            {/* Emergency Contact Phone */}
            <div className="md:row-start-2 md:col-start-2 max-md:col-span-2 sm:row-start-4 w-full md:w-full">
              <div className="label">
                {errors.emergencyContactPhone && (
                  <span className="label-text text-error">
                    {errors.emergencyContactPhone.message}
                  </span>
                )}
                {!errors.emergencyContactPhone && (
                  <span className="label-text">Emergency Contact Phone</span>
                )}
              </div>
              <label className="input input-bordered flex items-center gap-2">
                <Phone size={20} />
                <input
                  {...register("emergencyContactPhone", {
                    required: "Emergency Contact Phone is required",
                    pattern: {
                      value: /^[0-9]{11}$/,
                      message: "Please enter a valid 11-digit phone number",
                    },
                  })}
                  type="tel"
                  maxLength="11"
                  className="grow"
                  placeholder="07123 456789"
                />
                {!filledFields.emergencyContactPhone && (
                  <span className="badge badge-accent badge-xs"></span>
                )}
              </label>
            </div>

            {/* Staff Avatar */}
            <div className="md:row-start-3 md:col-start-1 col-span-2 w-full">
              <StaffProfilePicCard />
            </div>

            {/* Address */}
            <div className="md:row-start-2 md:col-start-3 col-span-2 row-span-2 w-full">
              <div className="label">
                {errors.addressLine1 && (
                  <span className="label-text text-error">
                    {errors.addressLine1.message}
                  </span>
                )}
                {!errors.addressLine1 && (
                  <span className="label-text">Address</span>
                )}
              </div>
              <label className="input input-bordered flex items-center gap-2 w-full">
                <input
                  {...register("addressLine1", {
                    required: "Address line 1 is required",
                  })}
                  type="text"
                  className="grow"
                  placeholder="Address Line 1"
                  onChange={(e) => {
                    const capitalized = capitalizeWords(e.target.value);
                    e.target.value = capitalized;
                    setValue("addressLine1", capitalized);
                  }}
                />
                {!filledFields.addressLine1 && (
                  <span className="badge badge-accent badge-xs"></span>
                )}
              </label>
              <label className="input input-bordered flex items-center gap-2 w-full">
                <input
                  {...register("addressLine2")}
                  type="text"
                  className="grow"
                  placeholder="Address Line 2"
                  onChange={(e) => {
                    const capitalized = capitalizeWords(e.target.value);
                    e.target.value = capitalized;
                    setValue("addressLine2", capitalized);
                  }}
                />
              </label>
              <label className="input input-bordered flex items-center gap-2 w-full">
                <input
                  {...register("addressLine3")}
                  type="text"
                  className="grow"
                  placeholder="Address Line 3"
                  onChange={(e) => {
                    const capitalized = capitalizeWords(e.target.value);
                    e.target.value = capitalized;
                    setValue("addressLine3", capitalized);
                  }}
                />
              </label>

              <label className="input input-bordered flex items-center gap-2 w-full ">
                <input
                  {...register("postCode", {
                    required: "Post code is required",
                    pattern: {
                      value: /^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/,
                      message: "Please enter a valid UK post code",
                    },
                  })}
                  type="text"
                  className="grow"
                  placeholder="Post Code"
                  onChange={(e) => {
                    const upperCase = e.target.value.toUpperCase();
                    e.target.value = upperCase;
                    setValue("postCode", upperCase);
                  }}
                />
                {!filledFields.postCode && (
                  <span className="badge badge-accent badge-xs"></span>
                )}
              </label>
              <div className="label">
                {errors.postCode && (
                  <span className="label-text text-error">
                    {errors.postCode.message}
                  </span>
                )}
              </div>

              <label className="form-control pt-14 px-auto">
                <div className="label">
                  <span className="label-text">Change Your Password</span>
                </div>
                <button
                  className="btn btn-secondary btn-wide mb-0"
                  type="button"
                  onClick={() => {
                    console.log("Change password button clicked");
                    // Implement password change logic here
                  }}>
                  <Password size={25} />
                  Change your password
                </button>
              </label>
            </div>
            
            {/* Submit Button */}
            <div className="md:row-start-4 col-start-1 col-span-4 w-full">
              <button
                className="btn btn-secondary btn-wide mb-0"
                type="submit"
                disabled={isLoading}>
                <CheckCircle size={25} />
                {isLoading ? "Updating..." : "Update Information"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

// Add comprehensive logging
console.log("StaffProfileInfo component loaded");

// Error boundary component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by boundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong. Please try refreshing the page.</h1>;
    }

    return this.props.children;
  }
}

// Wrap the StaffProfileInfo component with the ErrorBoundary
const StaffProfileInfoWithErrorBoundary = () => (
  <ErrorBoundary>
    <StaffProfileInfo />
  </ErrorBoundary>
);
