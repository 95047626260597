import { Link } from "react-router-dom";

export default function Dashboard() {
  return (
    <>
      <div className="bg-base-100 px-3 pt-5">
        <div className="grid grid-cols-4 pb-16 grid-rows-6 gap-4 px-16">
          <Link to="/CustomerInformation">
            <div className="card bg-base-200 w-full shadow-xl h-48 hover:bg-base-100 hover:ring hover:ring-primary">
              <div className="card-body cardText">
                <h2 className="cardTitle divider divider-start divider-primary ">
                  Customer Information
                </h2>
                <p>Customer Information Lookup Page</p>
              </div>
            </div>
          </Link>
          <Link to="/EstimateInformation">
            <div className="card bg-base-200 w-full shadow-xl h-48 hover:bg-base-100 hover:ring hover:ring-primary">
              <div className="card-body cardText">
                <h2 className="cardTitle divider divider-start divider-primary ">
                  Estimate Information
                </h2>
                <p>Search Estimates Here </p>
              </div>
            </div>
          </Link>
          <Link to="/AllocatedEstimates">
            <div className="card bg-base-200 w-full shadow-xl h-48 hover:bg-base-100 hover:ring hover:ring-primary">
              <div className="card-body cardText">
                <h2 className="cardTitle divider divider-start divider-primary ">
                  Allocated Estimates
                </h2>
                <p>See Estimates Allocated to You.</p>
              </div>
            </div>
          </Link>
          <Link to="/DefaultCosts">
            <div className="card bg-base-200 w-full shadow-xl h-48 hover:bg-base-100 hover:ring hover:ring-primary">
              <div className="card-body cardText">
                <h2 className="cardTitle divider divider-start divider-primary ">
                  Default Costs
                </h2>
                <p>Edit the default labour and material costs for new customers.</p>
              </div>
            </div>
          </Link>
          <Link to="/AllocateWork">
            <div className="card bg-base-200 w-full shadow-xl h-48 hover:bg-base-100 hover:ring hover:ring-primary">
              <div className="card-body cardText">
                <h2 className="cardTitle divider divider-start divider-primary ">
                  Allocate Work
                </h2>
                <p>Allocate estimates to staff</p>
              </div>
            </div>
          </Link>
          <Link to="/CreateStaffUser">
            <div className="card bg-base-200 w-full shadow-xl h-48 hover:bg-base-100 hover:ring hover:ring-primary">
              <div className="card-body cardText">
                <h2 className="cardTitle divider divider-start divider-primary ">
                  Create a Staff User
                </h2>
                <p>Create a profile for a new member of staff.</p>
              </div>
            </div>
          </Link>
          <Link to="/CustomerInformation">
            <div className="card bg-base-200 w-full shadow-xl h-48 hover:bg-base-100 hover:ring hover:ring-primary">
              <div className="card-body cardText">
                <h2 className="cardTitle divider divider-start divider-primary ">
                  Card title!
                </h2>
                <p>If a dog chews shoes whose shoes does he choose?</p>
              </div>
            </div>
          </Link>
          <Link to="/CustomerInformation">
            <div className="card bg-base-200 w-full shadow-xl h-48 hover:bg-base-100 hover:ring hover:ring-primary">
              <div className="card-body cardText">
                <h2 className="cardTitle divider divider-start divider-primary ">
                  Card title!
                </h2>
                <p>If a dog chews shoes whose shoes does he choose?</p>
              </div>
            </div>
          </Link>
          <Link to="/CustomerInformation">
            <div className="card bg-base-200 w-full shadow-xl h-48 hover:bg-base-100 hover:ring hover:ring-primary">
              <div className="card-body cardText">
                <h2 className="cardTitle divider divider-start divider-primary ">
                  Card title!
                </h2>
                <p>If a dog chews shoes whose shoes does he choose?</p>
              </div>
            </div>
          </Link>
          <Link to="/CustomerInformation">
            <div className="card bg-base-200 w-full shadow-xl h-48 hover:bg-base-100 hover:ring hover:ring-primary">
              <div className="card-body cardText">
                <h2 className="cardTitle divider divider-start divider-primary ">
                  Card title!
                </h2>
                <p>If a dog chews shoes whose shoes does he choose?</p>
              </div>
            </div>
          </Link>
          <Link to="/CustomerInformation">
            <div className="card bg-base-200 w-full shadow-xl h-48 hover:bg-base-100 hover:ring hover:ring-primary">
              <div className="card-body cardText">
                <h2 className="cardTitle divider divider-start divider-primary ">
                  Card title!
                </h2>
                <p>If a dog chews shoes whose shoes does he choose?</p>
              </div>
            </div>
          </Link>
          <Link to="/CustomerInformation">
            <div className="card bg-base-200 w-full shadow-xl h-48 hover:bg-base-100 hover:ring hover:ring-primary">
              <div className="card-body cardText">
                <h2 className="cardTitle divider divider-start divider-primary ">
                  Card title!
                </h2>
                <p>If a dog chews shoes whose shoes does he choose?</p>
              </div>
            </div>
          </Link>
          <Link to="/CustomerInformation">
            <div className="card bg-base-200 w-full shadow-xl h-48 hover:bg-base-100 hover:ring hover:ring-primary">
              <div className="card-body cardText">
                <h2 className="cardTitle divider divider-start divider-primary ">
                  Card title!
                </h2>
                <p>If a dog chews shoes whose shoes does he choose?</p>
              </div>
            </div>
          </Link>
          <Link to="/CustomerInformation">
            <div className="card bg-base-200 w-full shadow-xl h-48 hover:bg-base-100 hover:ring hover:ring-primary">
              <div className="card-body cardText">
                <h2 className="cardTitle divider divider-start divider-primary ">
                  Card title!
                </h2>
                <p>If a dog chews shoes whose shoes does he choose?</p>
              </div>
            </div>
          </Link>
          <Link to="/CustomerInformation">
            <div className="card bg-base-200 w-full shadow-xl h-48 hover:bg-base-100 hover:ring hover:ring-primary">
              <div className="card-body cardText">
                <h2 className="cardTitle divider divider-start divider-primary ">
                  Card title!
                </h2>
                <p>If a dog chews shoes whose shoes does he choose?</p>
              </div>
            </div>
          </Link>
          <Link to="/CustomerInformation">
            <div className="card bg-base-200 w-full shadow-xl h-48 hover:bg-base-100 hover:ring hover:ring-primary">
              <div className="card-body cardText">
                <h2 className="cardTitle divider divider-start divider-primary ">
                  Card title!
                </h2>
                <p>If a dog chews shoes whose shoes does he choose?</p>
              </div>
            </div>
          </Link>
          <Link to="/CustomerInformation">
            <div className="card bg-base-200 w-full shadow-xl h-48 hover:bg-base-100 hover:ring hover:ring-primary">
              <div className="card-body cardText">
                <h2 className="cardTitle divider divider-start divider-primary ">
                  Card title!
                </h2>
                <p>If a dog chews shoes whose shoes does he choose?</p>
              </div>
            </div>
          </Link>
          <Link to="/CustomerInformation">
            <div className="card bg-base-200 w-full shadow-xl h-48 hover:bg-base-100 hover:ring hover:ring-primary">
              <div className="card-body cardText">
                <h2 className="cardTitle divider divider-start divider-primary ">
                  Card title!
                </h2>
                <p>If a dog chews shoes whose shoes does he choose?</p>
              </div>
            </div>
          </Link>
          <Link to="/CustomerInformation">
            <div className="card bg-base-200 w-full shadow-xl h-48 hover:bg-base-100 hover:ring hover:ring-primary">
              <div className="card-body cardText">
                <h2 className="cardTitle divider divider-start divider-primary ">
                  Card title!
                </h2>
                <p>If a dog chews shoes whose shoes does he choose?</p>
              </div>
            </div>
          </Link>
          <Link to="/CustomerInformation">
            <div className="card bg-base-200 w-full shadow-xl h-48 hover:bg-base-100 hover:ring hover:ring-primary">
              <div className="card-body cardText">
                <h2 className="cardTitle divider divider-start divider-primary ">
                  Card title!
                </h2>
                <p>If a dog chews shoes whose shoes does he choose?</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}
