export default function Footer() {
  return (
    <>
      <footer className="socials footer bg-neutral text-neutral-content items-center p-2">
        <nav className="grid-flow-col gap-3 place-self-center items-center">
          <aside className="grid-flow-col items-center">
            <div>
              <span className="text-lg">
                Copyright &copy; {new Date().getFullYear()}
                <span className="ae-logo ps-2 footerlogo">æ</span>
                <span className="max-sm:hidden">&nbsp;&nbsp;</span>
                <span>APERSU</span>
                <span>
                  <b>ESTIMATING</b>
                </span>
              </span>
            </div>
          </aside>
        </nav>
      </footer>
    </>
  );
}
